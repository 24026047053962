import React, { useEffect, useRef, useState,lazy, Suspense} from "react";
import './App.css'
import Navbar from './components/Navbar';
import Home from './pages/Home';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Ellipse2 from '../src/background/Ellipse2.webp';
import Ellipse3 from '../src/background/Ellipse3.webp';
import Ellipse4 from '../src/background/Ellipse4.webp';
import Ellipse5 from '../src/background/Ellipse5.webp';
import Ellipse6 from '../src/background/Ellipse6.webp';
import Ellipse7 from '../src/background/Ellipse7.webp';
import Ellipse8 from '../src/background/Ellipse8.webp';
import Ellipse9 from '../src/background/Ellipse9.webp';
import Ellipse10 from '../src/background/Ellipse10.webp';
import Ellipse11 from '../src/background/Ellipse11.webp';
import Karnataka from '../src/background/www.microdegree.work (27).png';


import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
// import Footer from './components/Footer';

import SearchedOutput from './pages/SearchedOutput';
const Footer = lazy(() => import('./components/Footer'));
const Recognition = lazy(()=>import('./components/Recognition'));
const Aws_Course_Details_Page = lazy(()=>import('./pages/Aws_Course_Details_Page'));
const Python_Course_Details_Page = lazy(()=>import('./pages/Python_Course_Details_Page'));
const Mysql_Course_Details_Page = lazy(()=>import('./pages/Mysql_Course_Details_Page'));
const E_Book_2024 =lazy(()=>import('./pages/E_Book_2024'));
const Streamyardpage =lazy(()=>import('./pages/Streamyardpage'));
const TestimonialsPage = lazy(()=>import('./pages/TestimonialsPage'));
const Ebook = lazy(()=>import('./pages/Ebook'));
const Survey =lazy(()=>import('./pages/Survey'));
const Courses =lazy(()=>import('./pages/Courses'));
const Webinar =lazy(()=>import('./components/Webinar/Webinar'));
const Hero =lazy(()=>import('./components/Webinar/Hero'));
const Hero2 =lazy(()=>import('./components/Webinar/Hero2'));
const Resume =lazy(()=>import('./components/Resume'));
const TestimonialFreshers = lazy(()=>import('./pages/TestimonialFreshers'));
const TestimonialExperienced = lazy(()=>import('./pages/TestimonialExperienced'));
const Cloud_PlacementDrive_Form = lazy(()=>import('./pages/Cloud_PlacementDrive_Form'));
const Offcampus_PlacementDrive_Form = lazy(()=>import('./pages/Offcampus_PlacementDrive_Form'));
const Testimonials = lazy(()=>import('./pages/Testimonials'));
// const SalaryFinder = lazy(()=>import('./components/SalaryCalculator'));
const YTFullCoursePage = lazy(() => import('./pages/YTFullCoursePage'));
const Legal =lazy(()=>import('./pages/Legal'));

// import ReactGA from 'react-ga';
const Whatsapp =lazy(()=>import('./pages/Whatsapp'));
const Kn_Blogs =lazy(()=>import('./pages/Kn_Blog'));
const JoinDemo =lazy(()=>import('./pages/JoinDemo'));

//import Powerbi_JoinDemo from './pages/Powerbi_JoinDemo';
const Task_Steps =lazy(()=>import('./pages/Task_Steps'));
const Privacy =lazy(()=>import('./pages/Privacy'));
const BankDetails =lazy(()=>import('./pages/BankDetails'));
//import Hiring from './pages/Hiring';
//import JoinDrive from './pages/JoinDrive';
const ThankYou =lazy(()=>import('./pages/ThankYou'));
const Thank_You =lazy(()=>import('./pages/Thank_You'));
const AWS_FAQ =lazy(()=>import('./pages/AWS_FAQ'));
//import BlogDetails from './pages/BlogDetails';
const Demo_Form =lazy(()=>import('./pages/Demo_Form'));
const AWS_DevOps  =lazy(()=>import('./pages/AWS_DevOps'));
const FreeTrial  =lazy(()=>import('./pages/Free_Trial'));
//import PowerBI_Tableau from './pages/PowerBI_Tableau';
//import Double from './pages/Double';
//import Course_Offer from './pages/Course_Offer';
const Zoom_Link_AWS =lazy(()=>import('./pages/Zoom_Link_AWS'));
//import Whatsapp_PBI from './pages/Whatsapp_PBI';
const Webinar_Youtube_Live =lazy(()=>import('./pages/Webinar_Youtube_Live'));
//import Zoom_Link_PBI from './pages/Zoom_Link_PBI';
const Webinar_registration =lazy(()=>import('./pages/Webinar_Registration'));
const Company_Vacancies =lazy(()=>import('./pages/Company_Vacancies'));
const Webinar_Link =lazy(()=>import('./pages/Webinar_Link'));
const E_Book =lazy(()=>import('./pages/E_Book'));
const EBook_SheetFill =lazy(()=>import('./pages/EBook_SheetFill'));
//import React, { useEffect, useRef, useState } from "react";
//import Referral from './pages/Referral';
//import Recipes from './pages/Recipes';
// import E_Book from './pages/E_Book';
const Ship_Delivery_Policy =lazy(()=>import('./pages/Ship_Delivery_Policy'));
const E_Book_Aug18 =lazy(()=>import('./pages/E_Book_Aug18'));
const AWS_DevOps_Curriculm =lazy(()=>import('./pages/AWS_DevOps_Curriculum'));
const AWS_Demo_Link =lazy(()=>import('./pages/AWS_Demo_Link'));
const PaymentLink =lazy(()=>import('./pages/PaymentLink'));
const Devopsforkannadigas =lazy(()=>import('./pages/Devopsforkannadigas'));
const Pythonforkannadigas =lazy(()=>import('./pages/Pythonforkannadigas'));
const PowerBi =lazy(()=>import('./pages/PowerBi'));
const Java_at =lazy(()=>import('./pages/Java-at'));
const Topper_of_the_month =lazy(()=>import('./pages/Topper_of_the_month'));
const PythonQuiz =lazy(()=>import('./pages/PythonQuiz'));
const Awsdevops =lazy(()=>import('./components/Aws & DevOps/Awsdevops'));
const Pythonfullstack =lazy(()=>import('./components/Python-Fullstack/Pythonfullstack'));
const Pythonfullstack2 =lazy(()=>import('./components/Python-Fullstack-New/Pythonfullstack'));
const DockerAndKubernetesCertificationPage =lazy(()=>import('./pages/DockerAndKubernetesCertificationPage'));
const TRACKING_ID = "UA-240891607-1";


function App() {

  const [searchedResults, setSearchedResults] = useState([])
  const [alertStatus, setAlertStatus] = useState(false)

  
  const handleAlert =()=>{
    setAlertStatus(!alertStatus)
    setTimeout(() => (
      setAlertStatus(false)
    ), 4000);
  }

  const ref = useRef(null);

  const myfunc = () => {
    console.log("I was activated 5 seconds later");
  };

  useEffect(() => {
    setTimeout(() => {
      ref.current.click();
    }, 1000); //miliseconds
  }, []);

  var path = window.location.search;
  var sub = path.split("?");
  

  return (
    <div className="App relative w-full h-full overflow-x-hidden overflow-y-hidden font-poppins">
        <div className='absolute top-0 bg-[#f9f8fd] -z-10 w-full h-full '>
          <div className='relative h-full '>
        

        </div>
        </div>
{/* Home section starts here */}
      <div className='  overflow-x-hidden w-full h-full relative '>

         <div className={` ${alertStatus?"block":'hidden'} fixed z-40 w-full h-screen flex justify-center items-end py-20 sm:items-end sm:py-5`}>
          <div class="bg-green-500 rounded-b text-white px-4 py-1 shadow-md" role="alert">
      <div class="flex">
        <div class="py-1"><svg class="fill-current h-6 w-6 text-white mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
        <div className=' flex flex-col justify-center items-start'>
          <p class="font-medium">Submitted Successfully</p>
          <p class="font-light text-xs xl:text-sm">Our Team will contact you shortly</p>
        </div>
      </div>
    </div>
    </div>

      <Router>
      <Navbar setSearchedResults={setSearchedResults}></Navbar>
      <Suspense fallback={<h1>Loading...</h1>}>
      <Routes>
        {
          searchedResults.length!==0?<Route exact path="/" element={<SearchedOutput searchedResults={searchedResults} setSearchedResults={setSearchedResults}></SearchedOutput>}/>:<Route exact path="/" element={<Home handleAlert={handleAlert}></Home>}/>
        }
        {/* <Route exact path="/courses" element={<CoursePage></CoursePage>}/> */}
        <Route exact path='/Recognition' element={<Recognition/>}/>
        <Route exact path='/Aws_Course_Details_Page' element={<Aws_Course_Details_Page/>} />
        <Route exact path='/Python_Course_Details_Page' element={<Python_Course_Details_Page/>} />
        <Route exact path='/Mysql_Course_Details_Page' element={<Mysql_Course_Details_Page/>} />
        <Route exact path='/e_book_2024' element={<E_Book_2024/>} />
        <Route exact path='/streamyardpage' element={<Streamyardpage/>} />
        <Route exact path='/TestimonialFreshers' element={<TestimonialFreshers/>}/>
        <Route exact path='/TestimonialExperienced' element={<TestimonialExperienced/>}/>
        <Route exact path='/TestimonialsPage' element={<TestimonialsPage/>}/>
        <Route exact path='/ebook' element={<Ebook/>} />
        <Route exact path='/survey' element={<Survey/>} />
        <Route exact path='/zoom_link_aws' element={<Zoom_Link_AWS/>} />
        <Route exact path='/courses' element={<Courses/>} />
        <Route exact path='/whatsapp' element={<Whatsapp/>} />
        <Route exact path='/kn_blogs' element={<Kn_Blogs/>} />
        <Route exact path='/aws_faq' element={<AWS_FAQ/>} />
        <Route exact path='/demo_form' element={<Demo_Form/>} />
        <Route exact path='/joindemo' element={<JoinDemo/>} />
        <Route exact path='/aws_devops' element={<AWS_DevOps/>} />
        <Route exact path='/bankdetails' element={<BankDetails/>} />
        <Route exact path='/thankyou' element={<ThankYou/>} />
        <Route exact path='/thank_you' element={<Thank_You/>} />
        <Route exact path='/refund-and-course-rescheduling-policy' element={<Privacy/>} />
        <Route exact path='/task_steps' element={<Task_Steps/>} />
        <Route exact path='/webinar_youtube_live' element={<Webinar_Youtube_Live/>} />
        <Route exact path='/webinar_registration' element={<Webinar_registration/>} />
        <Route exact path='/company_vacancies' element={<Company_Vacancies/>} />
        <Route exact path='/webinar_link' element={<Webinar_Link/>} />
        <Route exact path='/e_book' element={<E_Book/>} />
        {window.location.pathname === "/e_book_sheet_fill" ? <Navbar /> : null}
        <Route exact path='/e_book_sheet_fill' element={<EBook_SheetFill/>} />
        <Route exact path='/ship-and-delivery-policy' element={<Ship_Delivery_Policy/>} />
        <Route exact path='/aws_devops_curriculm' element={<AWS_DevOps_Curriculm/>} />
        <Route exact path='/aws_demo_link' element={<AWS_Demo_Link/>} />
        <Route exact path='/webinar' element={<Webinar/>} />
        <Route exact path='/resume' element={<Resume/>} />
        <Route exact path='/TestimonialFreshers' element={<TestimonialFreshers/>}/>
        <Route exact path='/TestimonialExperienced' element={<TestimonialExperienced/>}/>
        <Route exact path='/Cloud_PlacementDrive_Form' element={<Cloud_PlacementDrive_Form/>}/>
        <Route exact path='/Offcampus_PlacementDrive_Form' element={<Offcampus_PlacementDrive_Form/>}/>
        <Route exact path='/Testimonials' element={<Testimonials/>}/>
        {/* <Route exact path='/Salarycalculator' element={<SalaryFinder/>}/> */}
        <Route exact path='/YTFullCoursePage' element={<YTFullCoursePage />} />
        <Route exact path='/Legal' element={<Legal />} />
        <Route exact path='/bookyourseat' element={<PaymentLink />} />
        <Route exact path='/devopsforkannadigas' element={<Devopsforkannadigas />} />
        <Route exact path='/pythonforkannadigas' element={<Pythonforkannadigas />} />
        <Route exact path='/PowerBi' element={<PowerBi />} />
        <Route exact path='/java-at' element={<Java_at />} />
        <Route exact path='/topper-of-the-month' element={<Topper_of_the_month />} />
        <Route exact path='/pythonquiz' element={<PythonQuiz />} />
        <Route exact path='/Awsdevops' element={<Awsdevops />} />
        <Route exact path='/Pythonfullstack' element={<Pythonfullstack />} />
        <Route exact path='/Pythonfull-stack' element={<Pythonfullstack2 />} />
        <Route exact path='/dockerandkubernetes' element={<DockerAndKubernetesCertificationPage/>} />

   </Routes>
   </Suspense>
   <Footer></Footer>
        </Router>
        
      </div>
      {/* <button data-tally-open="mOzMQY" data-tally-emoji-text="👋" data-tally-emoji-animation="wave" ref={ref} onClick={myfunc}></button> */}

    </div>

  );
}

export default App;